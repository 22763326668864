// Import dependencies
import React from "react";

// Import components
import Layout from "../layouts/layout";
import AdminLogIn from "../components/admin/adminLogIn";
import AdminLayout from "../components/admin/adminLayout";
import SEO from "../components/seo/seo";
import {getFirebase} from "../api/firebase";

// Import styles
import "./account.css"

/*
    Admin Cabinet page
*/
export default class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      paymentCount: 0,
      orderCount: 0,
    };
    this.logIn = this.logIn.bind(this);
    this.checkNotifCount = this.checkNotifCount.bind(this);
    this.setOrderPaymentCount = this.setOrderPaymentCount.bind(this);
  }

  // Loggs user in
  logIn() {
    this.setState({ loggedIn: true });
  }

  

  checkNotifCount() {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")    
    Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
        .firestore().collection("payments").where("paid_status", "==", false).get().then((querySnapshot) => {
          this.setState({paymentCount: querySnapshot.size});
        });
        getFirebase(firebase)
        .firestore().collection("orders").where("paid_status", "==", false).get().then((querySnapshot) => {
          this.setState({orderCount: querySnapshot.size});
        });
    });
  }

  setOrderPaymentCount(order, payment) {
    if (order != -1) this.setState({orderCount: order});
    if (payment != -1) this.setState({paymentCount: payment});
  }

  componentDidMount() {
    this.checkNotifCount();
  }

  render() {
    return (
      <Layout noHeader noFooter adminPage>        
        <SEO title="Admin" />
        {this.state.loggedIn ? (
          <AdminLayout 
          paymentCount={this.state.paymentCount}
          orderCount={this.state.orderCount}
          setOrderPaymentCount={this.setOrderPaymentCount}
        />
        ) : (
          <AdminLogIn
            onClickLogin={this.logIn} />
        )}
      </Layout>
    );
  }
}
