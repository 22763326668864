// Import dependencies
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

// Import components
import AdminLoginComponent from "./loginComponent"

// Import styles
import "../../pages/account.css"
import "../../pages/forgot-password.css"

/*
    Log in page for Admin cabinet
*/
const AdminLogIn = ({ onClickLogin }) => {
  // GraphQL image query
  const images = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="admin_login_container">
      <AdminLoginComponent logIn={onClickLogin} />
    </div>
  )
}

export default AdminLogIn
