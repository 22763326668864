// Import dependencies
import React from "react";
import {Spin, Input, Button} from "antd";

// Import components
import {TitleText, ParagraphText} from "../../styled-components/UILibrary";

// Import firebase
import {getFirebase} from "../../api/firebase";
import {Constants} from "../../constants/Constants";

import { FileUploader } from "react-drag-drop-files";
import { parse } from 'papaparse';

import "./uploadPremiumPrices.css"

const { TextArea } = Input;


const RESTRICTED_VALUES = [
    'Category,Brand,Model,"Not good',
    'Dead battery","Clearly used',
    'Dead battery","Looks new',
    'Dead battery","Not good',
    'Turns on","Clearly used',
    'Turns on","Looks new',
    'Scrap',
    'Non-members prices by condition',
    'Members prices by condition"',
    'Department',
    ',,,,Scrap,Non-members prices by condition,,,,,,,,,Members prices by condition,,,,,,,,',

];

const REGEX = /(".*?"|[^",]+)(?=\s*,|\s*$)/g;

export default class UploadPremiumPrices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPrices: "",
            products: {},
            loading: false,
            message: null,
            firebase: null,
            pendingBatchCount: 0,
            deleteInitiationInProgress: false,
            writeInitiationInProgress: false,
            uploadSuccessful: true,
            hideMessage: false,
            csvFile: null,
            loadingCount: 0,
            loadingTotal: 0
        };
        this.processNewPrices = this.processNewPrices.bind(this);
        this.getCsvFile = this.getCsvFile.bind(this);
        this.readFile = this.readFile.bind(this);
        this.uploadPriceTable = this.uploadPriceTable.bind(this);
        this.parseCSV = this.parseCSV.bind(this);
        
    }

    serialize(string) {
        const input = string.split("");
        if (input[input.length - 2] === " ") input.splice(input.length - 2, 1);

        let output = "";
        for (let i = 0; i < input.length; i++) {
            if (input[i + 1] && input[i] === '"' && input[i + 1] === '"') {
                output += input[i];
                continue;
            } else if (input[i] === '"') {
                continue;
            }
            output += input[i];
        }
        return output;
    }

    readFile() {
        const file = this.state.csvFile;
        return new Promise((resolve, reject) => {
          var fr = new FileReader();  
          fr.onload = (e) => {
            resolve(e.target.result)
          };
          fr.onerror = reject;
          fr.readAsText(file);
        });
      }

    async getCsvFile() {
        const result = await this.readFile();
        this.processNewPrices(result);
    }

    async processNewPrices(text) {
        this.setState({loading: true, message: null});

        const csvValues = text.split("\n");

        this.setState({loadingTotal: csvValues.length});
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");


        Promise.all([app, firestore]).then(([firebase]) => {

            getFirebase(firebase)
            .firestore()
            .collection('products').get().then(querySnapshot => {
                querySnapshot.docs.forEach(snapshot => {
                    snapshot.ref.delete();
                })
            })
            .then(() => console.log("ALL DELETED"))
            .then(() => {
                Promise.all([app, firestore]).then(([firebase]) => {
    
                    for (let i = 0; i < csvValues.length; i++) {
                        const row = csvValues[i].split(',');
                        const item = {
                                "Department": row[0],
                                "Category": row[1],
                                "SubCategory": row[2],
                                "Product": row[3],
                                "ProductID": i.toString(),
                                "fixed-rate": row[9],
                                "Coins": row[8],
                                "Premium": (row[9] === "" ? 1 : 0).toString(),
                                "Non-Member": {
                                    "Malfunctioning": {
                                        "Not good": row[10],
                                        "Clearly used": row[11],
                                        "Looks new": row[12]
                                    },
                                    "Dead battery": {
                                        "Not good": row[13],
                                        "Clearly used": row[14],
                                        "Looks new": row[15]
                                    },
                                    "No problems": {
                                        "Not good": row[16],
                                        "Clearly used": row[17],
                                        "Looks new": row[18]
                                    }
                                },
                                "Member": {
                                    "Malfunctioning": {
                                        "Not good": row[19],
                                        "Clearly used": row[20],
                                        "Looks new": row[21]
                                    },
                                    "Dead battery": {
                                        "Not good": row[22],
                                        "Clearly used": row[23],
                                        "Looks new": row[24]
                                    },
                                    "No problems": {
                                        "Not good": row[25],
                                        "Clearly used": row[26],
                                        "Looks new": row[27]
                                    }
                                },
                                "Demand Score": row[4],
                                "ReusabilityScore": row[5],
                                "PriceCertainty": row[6],
                                "AvgWeight": row[7],
                                "MemberQuote": row[9],
                                "NonMemberQuote": "0"
                        };
    
                        getFirebase(firebase)
                        .firestore()
                        .collection("products")
                        .doc(item.ProductID.toString())
                        .set(item)
                        .then(() => {
                            console.log("DONE : ", item.ProductID);
                            this.setState({loadingCount: this.state.loadingCount+1});
                            if (i == csvValues.length-1) {
                                this.setState({
                                    loading: false,
                                    message: "Upload successful",
                                    loadingCount: 0,
                                    loadingTotal: 0,
                                    hideMessage: true
                                });
                            }
                        })
                        .catch((error) => {
                            console.log("NOT DONE : ", item.ProductID);
                        });
    
    
                    }
    
    
                })
                .catch((error) => {
                    this.setState({loading: false, message: "Upload failed"});
                });
    
            })
            
        })
        .catch((error) => {
            this.setState({loading: false, message: "Upload failed"});
        });

    }

    onWriteBatchComplete() {
        if (this.state.pendingBatchCount == 0 && !this.state.writeInitiationInProgress) {
            console.log("write documents completed");
            this.setState({
                loading: false,
                message: (this.state.uploadSuccessful ? "Upload successful" : "Upload Failed"),
                hideMessage: false
            });
        }
    }

    onDeleteBatchComplete() {
        if (this.state.pendingBatchCount == 0 && !this.state.deleteInitiationInProgress) {
            this.setState({
                writeInitiationInProgress: true,
            })
            let i, tempArray, chunk = 500, batchCount = 0;
            const productIdArray = Object.keys(this.state.products);
            console.log("delete documents completed. Initiating write for " + productIdArray.length + " products");
            for (i = 0; i < productIdArray.length; i += chunk) {
                tempArray = productIdArray.slice(i, i + chunk);
                const db = getFirebase(this.state.firebase).firestore();
                const batch = db.batch();
                tempArray.forEach(productId => {
                    batch.set(db.collection("products").doc(productId), this.state.products[productId]);
                });
                batchCount += 1
                console.log("writing products from " + i + " to " + (i + chunk) + " out of " + productIdArray.length);
                batch.commit().then(() => {
                    console.log("writing products batch completed. remaining batches : " + (this.state.pendingBatchCount - 1));
                    this.setState((prevState) => {
                        return {
                            pendingBatchCount: prevState.pendingBatchCount - 1,
                        }
                    })
                    this.onWriteBatchComplete()
                }).catch((error) => {
                    this.setState((prevState) => {
                        return {
                            pendingBatchCount: prevState.pendingBatchCount - 1,
                            uploadSuccessful: false,
                        }
                    })
                    this.onWriteBatchComplete()
                });
            }
            console.log("Write for " + productIdArray.length + " documents initiated via " + batchCount + " batches");
            if (batchCount == 0) {
                this.setState({
                    writeInitiationInProgress: false,
                })
                this.onDeleteBatchComplete()
            } else {
                this.setState({
                    pendingBatchCount: batchCount,
                    writeInitiationInProgress: false,
                })
            }
        }
    }

    deleteAllDocuments(allDocuments, firebase) {
        const docRefArray = []
        allDocuments.forEach(element => {
            docRefArray.push(element.ref)
        })

        this.setState({
            firebase: firebase,
            deleteInitiationInProgress: true,
        })

        let i, tempArray, chunk = 500, batchCount = 0;
        for (i = 0; i < docRefArray.length; i += chunk) {
            tempArray = docRefArray.slice(i, i + chunk);
            const db = getFirebase(firebase).firestore();
            const batch = db.batch();
            tempArray.forEach(element => {
                batch.delete(element);
            });
            batchCount += 1
            console.log("deleting existing products from " + i + " to " + (i + chunk) + " out of " + docRefArray.length);
            batch.commit().then(() => {
                console.log("deleting product batch completed. remaining batches : " + (this.state.pendingBatchCount - 1));
                this.setState((prevState) => {
                    return {
                        pendingBatchCount: prevState.pendingBatchCount - 1,
                    }
                })
                this.onDeleteBatchComplete()
            }).catch((error) => {
                this.setState((prevState) => {
                    return {
                        pendingBatchCount: prevState.pendingBatchCount - 1,
                        uploadSuccessful: false,
                    }
                })
                this.onDeleteBatchComplete()
            });
        }
        console.log("delete for " + docRefArray.length + " documents initiated via " + batchCount + " batches");
        if (batchCount == 0) {
            this.setState({
                deleteInitiationInProgress: false,
            })
            this.onDeleteBatchComplete()
        } else {
            this.setState({
                pendingBatchCount: batchCount,
                deleteInitiationInProgress: false,
            })
        }
    }

    uploadPriceTable(newPrices) {
        if (Object.keys(newPrices).length > 1) {
            this.setState({
                products: newPrices,
            })
            const app = import("firebase/app");
            const firestore = import("firebase/firestore");
            Promise.all([app, firestore]).then(([firebase]) => {
                getFirebase(firebase).firestore().collection("products")
                    .get()
                    .then(res => {
                        this.deleteAllDocuments(res, firebase)
                    });
            });
        } else {
            this.setState({
                loading: false,
                uploadSuccessful: false,
                message: "There was an error parsing data",
                hideMessage: false
            });
        }
    }

    parseCSV(file) {
        
        parse(file, {
            header: true,
            complete: results => {
                const objArray = results.data;
                const objKeyArray = Object.keys(objArray[0]);
                const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                let str = objKeyArray.join(',') + "\n";
                for (let i = 0; i < array.length; i++) {
                    let line = '';
                    for (let index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }

                    str += line + '\n';
                }
                this.setState({newPrices: str}, function() {
                    this.processNewPrices();
                });
            },
        })
    }

    render() {
        const {loading, message} = this.state;

        return (
            <div className="upload_prices_container">
                <div className="upload_prices_title">
                    <h1>Upload new premium prices</h1>
                    <p>Upload the file in .CSV format</p>
                </div>


            <FileUploader handleChange={file => this.setState({csvFile: file}, function() {
                        this.getCsvFile();
                    })} name="file">
                <div className="upload_prices_file">
                    <svg width="60" height="41" viewBox="0 0 60 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M48.375 15.6C46.675 6.975 39.1 0.5 30 0.5C22.775 0.5 16.5 4.6 13.375 10.6C5.85 11.4 0 17.775 0 25.5C0 33.775 6.725 40.5 15 40.5H47.5C54.4 40.5 60 34.9 60 28C60 21.4 54.875 16.05 48.375 15.6ZM35 23V33H25V23H17.5L29.125 11.375C29.625 10.875 30.4 10.875 30.9 11.375L42.5 23H35Z" fill="#BDBDBD"/>
                    </svg>
                    <p>Drag and drop, or <span>Browse</span> to choose a file</p>
                </div>
            </FileUploader>

                    {loading && (
                        <div className="upload_prices_uploading">
                            Uploading... {"(" + this.state.loadingCount + "/" + this.state.loadingTotal +")"}
                        </div>
                    )}

                    {this.state.hideMessage && (
                        <div className="upload_prices_success">
                            {message}
                            <svg onClick={() => this.setState({hideMessage: false})}width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z" fill="#828282"/>
                            </svg>
                        </div>
                    )}
      
      </div>
    );
  }
}