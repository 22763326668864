// Import dependencies
import React from "react"
import { Spin, List, Input, Button, Tooltip, Icon } from "antd"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"

// Import firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

// Import utils
import { createFilter } from "../../util/filterByGeneralPrice"

const { Search } = Input

/*
    PromoCodes layout
*/
export default class PromoCodes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      filteredData: null,
      isLoading: true,
      message: "",
      promoCodeName: null,
      maxRedemptions: null,
      promoCodeValue: null,
    }
  }

  componentDidMount() {
    // Fetch data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("promo_codes")
        .get()
        .then(querySnapshot => {
          let result = {}
          querySnapshot.forEach(doc => {
            result[doc.id] = doc.data()
          })

          this.setState({
            data: result,
            isLoading: false,
          })
        })
    })
  }

  renderDescription = (maxRedemption, timesRedeemed) => {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 60 }}
        >
          <b>Maximum allowed redemptions</b>
          <p>{maxRedemption}</p>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 60 }}
        >
          <b>Already redeemed</b>
          <p>{timesRedeemed}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <b>Status</b>
          <p>{timesRedeemed < maxRedemption ? "Valid" : "Expired"}</p>
        </div>
      </div>
    )
  }

  addValueToDB = () => {
    if (this.state.promoCodeName && this.state.maxRedemptions) {
      const maxRedemption = parseInt(this.state.maxRedemptions)
      const promoCodeValue = parseInt(this.state.promoCodeValue)
      if (maxRedemption && promoCodeValue) {
        const app = import("firebase/app")
        const firestore = import("firebase/firestore")

        Promise.all([app, firestore]).then(([firebase]) => {
          getFirebase(firebase)
            .firestore()
            .collection("promo_codes")
            .add({
              max_redemption: maxRedemption,
              name: this.state.promoCodeName,
              times_redeemed: 0,
              value: promoCodeValue,
            })
            .then(() => this.setState({ message: "Successfully added." }))
            .catch()
        })
      } else {
        this.setState({
          message: "Max redemption and value should a whole number.",
        })
      }
    } else {
      this.setState({
        message: "Please enter promo code name, max redemption, and value.",
      })
    }
  }

  removeFromDB = key => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("promo_codes")
        .doc(key)
        .delete()
        .then(() => this.setState({ message: "Successfully removed." }))
        .catch(error => {
          this.setState({ message: error })
        })
    })
  }

  onChangeName = value => {
    this.setState({ promoCodeName: value })
  }
  onChangeMaxRedemption = value => {
    this.setState({ maxRedemptions: value })
  }
  onChangeValue = value => {
    this.setState({ promoCodeValue: value })
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "center",
          minHeight: "calc(100vh - 169px)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <TitleText subTitle left>
            Promo codes
          </TitleText>
          <Search
            placeholder="Search by name..."
            style={{ width: 300 }}
            onChange={e => this.filterByGeneral(e.target.value)}
          />
        </div>

        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
        >
          Add a promo code
        </ParagraphText>
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
          colour={
            this.state.message === "Successfully added." ||
            this.state.message === "Successfully removed."
              ? Constants.mainGreen
              : Constants.mainRed
          }
        >
          {this.state.message}
        </ParagraphText>
        <div
          style={{
            width: "100%",
            maxWidth: 800,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <Tooltip title="Promo code name.">
            <Input
              placeholder="Name..."
              style={{ marginRight: 25 }}
              onChange={e => this.onChangeName(e.target.value)}
            />
          </Tooltip>
          <Tooltip title="Maximum number of times this promo code can be redeemed.">
            <Input
              placeholder="Max redemption..."
              style={{ marginRight: 25 }}
              onChange={e => this.onChangeMaxRedemption(e.target.value)}
            />
          </Tooltip>
          <Tooltip title="Value of the promo code.">
            <Input
              placeholder="Value..."
              style={{ marginRight: 25 }}
              onChange={e => this.onChangeValue(e.target.value)}
            />
          </Tooltip>
          <Button type="primary" onClick={this.addValueToDB}>
            ADD
          </Button>
        </div>

        {this.state.isLoading ? (
          <Spin size="large" style={{ marginTop: 60 }} />
        ) : (
          <List
            itemLayout="horizontal"
            pagination={{
              pageSize: 20,
              hideOnSinglePage: true,
            }}
            dataSource={
              this.state.filteredData
                ? Object.keys(this.state.filteredData)
                : Object.keys(this.state.data)
            }
            style={{ marginTop: 35, maxWidth: 800, textAlign: "left" }}
            renderItem={item => (
              <List.Item
                actions={[
                  <span onClick={() => this.removeFromDB(item)}>
                    <Icon type="delete" />
                    <a style={{ marginLeft: 10 }}>remove</a>
                  </span>,
                ]}
              >
                <List.Item.Meta
                  title={this.state.data[item].name}
                  description={this.renderDescription(
                    this.state.data[item].max_redemption,
                    this.state.data[item].times_redeemed
                  )}
                />
              </List.Item>
            )}
          />
        )}
      </div>
    )
  }
}
