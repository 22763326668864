// Import dependencies
import React from "react"
import { Collapse, Descriptions, Card, Spin, Empty, Input } from "antd"

// Import components
import { TitleText } from "../../styled-components/UILibrary"

// Import firebase
import { getFirebase } from "../../api/firebase"

// Import utilities
import { createFilter } from "../../util/filter"

const { Panel } = Collapse
const { Search } = Input

/*
    Users layout
*/
export default class GeneralOrderLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderData: [],
      originalData: [],
      isLoading: true,
    }
    this.filterByName = this.filterByName.bind(this)
  }

  /*
      Filters first & last name according to the
      search string (keyWord)
  */
  filterData(keyWord) {
    if (this.state.orderData && this.state.orderData.length > 0) {
      if (keyWord !== "") {
        let data = this.state.orderData
        return data.filter(createFilter(keyWord))
      }
    }
  }
  /*
    Triggers filter function
  */
  filterByName = keyWord => {
    if (keyWord === "") {
      this.setState({ orderData: this.state.originalData })
      return
    }

    var result = this.filterData(keyWord)
    if (result) {
      this.setState({ orderData: result })
    } else {
      this.setState({ orderData: [] })
    }
  }

  componentDidMount() {
    // Fetch user data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("general_orders")
        .get()
        .then(querySnapshot => {
          let result = []

          querySnapshot.forEach(doc => {
            result.push({ id: doc.id, order: doc.data() })
          })

          this.setState({
            orderData: result,
            originalData: result,
            isLoading: false,
          })
        })
    })
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "center",
          minHeight: "calc(100vh - 169px)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <TitleText subTitle left>
            General orders
          </TitleText>
          <Search
            placeholder="Search by name..."
            style={{ width: 300 }}
            // onChange={e => this.filterByName(e.target.value)}
          />
        </div>

        {this.state.isLoading ? (
          <Spin size="large" style={{ marginTop: 60 }} />
        ) : this.state.orderData.length > 0 ? (
          this.state.orderData.map(item =>  (
            <Card
              key={item.id}
              title={"OrderID: " + item.id}
              style={{ textAlign: "left", marginTop: 30 }}
            >
              {/* Info Dropdown */}
              <Collapse accordion>
                <Panel header="Account info" key="1">
                  <Descriptions
                    bordered
                    style={{ marginBottom: 20 }}
                    layout="vertical"
                  >
                    {Object.keys(item.order.account_info).map((key, i) => (
                      <Descriptions.Item
                        label={key.split("_").join(" ")}
                        key={i}
                      >
                        {item.order.account_info[key]}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </Panel>
                {item.order.order_info &&
                <Panel header="Order info" key="2">
                  <Descriptions
                    title={
                      "Collection method: " +
                      item.order.order_info.collection_method
                    }
                    bordered
                    style={{ marginBottom: 20 }}
                    layout="vertical"
                  >
                    {Object.keys(item.order.order_info.collection_details).map(
                      (key, i) => (
                        <Descriptions.Item
                          label={key.split("_").join(" ")}
                          key={i}
                        >
                          {item.order.order_info.collection_details[key]}
                        </Descriptions.Item>
                      )
                    )}
                  </Descriptions>

                  <Descriptions
                    title={
                      "Credit preference: " +
                      item.order.order_info.credit_preference
                    }
                    bordered
                    style={{ marginBottom: 20 }}
                    layout="vertical"
                  >
                    {Object.keys(item.order.order_info.credit_details).map(
                      (key, i) => (
                        <Descriptions.Item
                          label={key.split("_").join(" ")}
                          key={i}
                        >
                          {item.order.order_info.credit_details[key]}
                        </Descriptions.Item>
                      )
                    )}
                  </Descriptions>
                </Panel>
                }

                <Panel header="Items" key="3">
                  {item.order.items.map((result, ind) => (
                    <Descriptions
                      bordered
                      style={{ marginBottom: 20 }}
                      layout="vertical"
                      key={ind}
                    >
                      {Object.keys(result).map((key, i) => (
                        <Descriptions.Item
                          label={key.split("_").join(" ")}
                          key={i}
                        >
                          {result[key]}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  ))}
                </Panel>
              </Collapse>
            </Card>
          ))
        ) : (
          <div style={{ width: "100%", marginTop: 80 }} key="1">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nothing found..."
            />
          </div>
        )}
      </div>
    )
  }
}
