// Import dependencies
import React from "react"
import { Spin } from "antd"
import { Link, navigate } from "gatsby"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"
import InputField from "../form/input/inputField"
import GeneralButton from "../form/buttons/GeneralButton"

// Import styles
import "../../pages/account.css"
import "../../pages/forgot-password.css"
import "../account/login.css"


// Import firebase
import { getFirebase } from "../../api/firebase";
import { Constants } from "../../constants/Constants";
import {Separator} from "../separators/separator";
import Logo from "../../assets/icons/logo_green_login.svg";

/*
    Login in component for Admins
*/
export default class AdminLoginComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      adminUID: "pfMNiDDkCbVU4LcPPid20o7QRR72",
      email: "",
      password: "",
      isLoading: false,
      error: null,
    }
    this.updateEmail = this.updateEmail.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.onClickLogin = this.onClickLogin.bind(this)
  }

  updateEmail = e => {
    this.setState({ email: e.target.value })
  }
  updatePassword = e => {
    this.setState({ password: e.target.value })
  }

  onClickLogin() {
    // Reset errors
    this.setState({ error: null, isLoading: true })

    if (this.state.email !== "" && this.state.password !== "") {
      const app = import("firebase/app")
      const auth = import("firebase/auth")

      Promise.all([app, auth]).then(([firebase]) => {
        // Authenticate in Firebase
        getFirebase(firebase)
          .auth()
          .signInWithEmailAndPassword(this.state.email, this.state.password)
          .then(() => {
            let user = firebase.auth().currentUser
            // Check if this is admin
            if (user.uid === this.state.adminUID || user.uid === "wnXByoAPgYUYfTOzEuMEo3g8A672") {
              this.props.logIn()
            } else {
              // Handle Errors here.
              this.setState({
                error: "You don't have admin rights.",
                isLoading: false,
              })
            }
          })
          .catch(error => {
            // Handle Errors here.
            this.setState({ error: error.message, isLoading: false })
          })
      })
    } else {
      this.setState({
        error: "Please enter valid credentials.",
        isLoading: false,
      })
    }
  }

  render() {
    return (
        <div className="account_form">
          <Link to="/" className="checkout_logo" >
            <Logo />
          </Link>
          <InputField placeholder="Email" noForm onChange={this.updateEmail} />
          <InputField placeholder="Password" password noForm onChange={this.updatePassword} />
          {this.state.error ? (
              <p className="account_error_text">
                {this.state.error}
              </p>
          ) : null}
          <span onClick={() => navigate("/forgot-password")} className="login_forgot_link">Forgot password?</span>
          <div onClick={() => this.onClickLogin()} className="account_form_btn">Log in</div>
        </div>
    )
  }
}
