// Import dependencies
import React from "react"
import { Collapse, Descriptions, Card, Spin, Empty, Input } from "antd"

// Import components
import { TitleText } from "../../styled-components/UILibrary"

// Import firebase
import { getFirebase } from "../../api/firebase"

// Import utilities
import { createFilter } from "../../util/filter"

const { Panel } = Collapse
const { Search } = Input

/*
    Users layout
*/
export default class UsersLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: [],
      originalData: [],
      isLoading: true,
    }
    this.filterByName = this.filterByName.bind(this)
  }

  /*
      Filters first & last name according to the
      search string (keyWord)
  */
  filterData(keyWord) {
    if (this.state.userData && this.state.userData.length > 0) {
      if (keyWord !== "") {
        let data = this.state.userData
        return data.filter(createFilter(keyWord))
      }
    }
  }
  /*
    Triggers filter function
  */
  filterByName = keyWord => {
    if (keyWord === "") {
      this.setState({ userData: this.state.originalData })
      return
    }

    var result = this.filterData(keyWord)
    if (result) {
      this.setState({ userData: result })
    } else {
      this.setState({ userData: [] })
    }
  }

  componentDidMount() {
    // Fetch user data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("users")
        .get()
        .then(querySnapshot => {
          let result = []

          querySnapshot.forEach(doc => {
            result.push({ uid: doc.id, info: doc.data() })
          })

          this.setState({
            userData: result,
            originalData: result,
            isLoading: false,
          })
        })
    })
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "center",
          minHeight: "calc(100vh - 169px)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <TitleText subTitle left>
            User list
          </TitleText>
          <Search
            placeholder="Search by email..."
            style={{ width: 300 }}
            onChange={e => this.filterByName(e.target.value)}
          />
        </div>

        {this.state.isLoading ? (
          <Spin size="large" style={{ marginTop: 60 }} />
        ) : this.state.userData.length > 0 ? (
          this.state.userData.map(item => (
            <Card
              key={item.uid}
              title={
                item.info.account_info.title
                  ? item.info.account_info.title
                  : "" +
                    " " +
                    item.info.account_info.first_name +
                    " " +
                    item.info.account_info.last_name
              }
              style={{
                textAlign: "left",
                marginBottom: 25,
              }}
            >
              {/* Info Dropdown */}
              <Collapse accordion>
                <Panel header="Account info" key="1">
                  <Descriptions
                    bordered
                    style={{ marginBottom: 20 }}
                    layout="vertical"
                  >
                    <Descriptions.Item label="Email">
                      {item.info.account_info.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {item.info.account_info.phone
                        ? item.info.account_info.phone
                        : "Not Specified"}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
                <Panel header="Contact info" key="2">
                  <Descriptions
                    bordered
                    style={{ marginBottom: 20 }}
                    layout="vertical"
                  >
                    <Descriptions.Item label="Home address">
                      {item.info.contact_info.home_address
                        ? item.info.contact_info.home_address
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Apt, suite, etc">
                      {item.info.contact_info.apt_suite_etc
                        ? item.info.contact_info.apt_suite_etc
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Country">
                      {item.info.contact_info.country
                        ? item.info.contact_info.country
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {item.info.contact_info.city
                        ? item.info.contact_info.city
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Postal code">
                      {item.info.contact_info.postal_code
                        ? item.info.contact_info.postal_code
                        : "Not Specified"}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
                <Panel header="Billing info" key="3">
                  <Descriptions
                    bordered
                    style={{ marginBottom: 20 }}
                    layout="vertical"
                  >
                    <Descriptions.Item label="Home address">
                      {item.info.billing_info.home_address
                        ? item.info.billing_info.home_address
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Apt, suite, etc">
                      {item.info.billing_info.apt_suite_etc
                        ? item.info.billing_info.apt_suite_etc
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Country">
                      {item.info.billing_info.country
                        ? item.info.billing_info.country
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {item.info.billing_info.city
                        ? item.info.billing_info.city
                        : "Not Specified"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Postal code">
                      {item.info.billing_info.postal_code
                        ? item.info.billing_info.postal_code
                        : "Not Specified"}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>

              {/* Orders Dropdown */}
              <Collapse accordion style={{ marginTop: 25 }}>
                <Panel header="Premium orders" key="1">
                  {item.info.premium_orders.length > 0 ? (
                    item.info.premium_orders.map(order => (
                      <Card
                        key={order.key}
                        title={"Order ID: " + order.key}
                        style={{
                          textAlign: "left",
                          marginBottom: 25,
                          backgroundColor: "#EFEFF3",
                          borderRadius: 25,
                        }}
                        bordered={false}
                      >
                        <Descriptions bordered layout="vertical">
                          {Object.keys(order).map((key, i) => (
                            <Descriptions.Item
                              label={key.split("_").join(" ")}
                              key={i}
                            >
                              {order[key]}
                            </Descriptions.Item>
                          ))}
                          >
                        </Descriptions>
                      </Card>
                    ))
                  ) : (
                    <div style={{ width: "100%" }} key="1">
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No orders"
                      />
                    </div>
                  )}
                </Panel>
                <Panel header="General orders" key="2">
                  {item.info.general_orders.length > 0 ? (
                    item.info.general_orders.map(order => (
                      <Card
                        key={order.key}
                        title={"Order ID: " + order.key}
                        style={{
                          textAlign: "left",
                          marginBottom: 25,
                          backgroundColor: "#EFEFF3",
                          borderRadius: 25,
                        }}
                        bordered={false}
                      >
                        <Descriptions bordered layout="vertical">
                          {Object.keys(order).map((key, i) => (
                            <Descriptions.Item
                              label={key.split("_").join(" ")}
                              key={i}
                            >
                              {order[key]}
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </Card>
                    ))
                  ) : (
                    <div style={{ width: "100%" }} key="1">
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No orders"
                      />
                    </div>
                  )}
                </Panel>
              </Collapse>
            </Card>
          ))
        ) : (
          <div style={{ width: "100%", marginTop: 80 }} key="1">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nothing found..."
            />
          </div>
        )}
      </div>
    )
  }
}
