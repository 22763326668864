// Import dependencies
import React from "react"
import { Spin, List, Input, Button } from "antd"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"

// Import firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

// Import utils
import { createFilter } from "../../util/filterByGeneralPrice"

const { Search } = Input

/*
    GeneralPrices layout
*/
export default class GeneralPrices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      isLoading: true,
      filteredData: null,
      device: null,
      price: null,
      message: "",
    }
  }

  componentDidMount() {
    // Fetch user data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("general_price_table")
        .doc("calculator")
        .onSnapshot(doc => {
          this.setState({ data: doc.data(), isLoading: false })
        })
    })
  }

  onChangeDevice = value => {
    this.setState({ device: value })
  }
  onChangePrice = value => {
    this.setState({ price: value })
  }

  addValueToDB = () => {
    if (this.state.price && this.state.device) {
      const app = import("firebase/app")
      const firestore = import("firebase/firestore")

      Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore()
          .collection("general_price_table")
          .doc("calculator")
          .set({
            ...this.state.data,
            [this.state.device]: this.state.price,
          })
          .then(() => this.setState({ message: "Successfully added" }))
          .catch()
      })
    } else {
      this.setState({ message: "Please select device and price." })
    }
  }

  removeValueFromDB = value => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    let newState = this.state.data
    delete newState[value]

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("general_price_table")
        .doc("calculator")
        .set(newState)
        .then(() => this.setState({ message: "Successfully removed" }))
        .catch()
    })
  }

  filterData(keyWord) {
    if (keyWord !== "") {
      return Object.keys(this.state.data).filter(createFilter(keyWord))
    }
  }

  filterByGeneral = keyWord => {
    if (keyWord === "") {
      this.setState({ filteredData: null })
      return
    }

    var result = this.filterData(keyWord)
    if (result) {
      let temp = {}
      Object.keys(this.state.data).map(item =>
        result.includes(item) ? (temp[item] = this.state.data[item]) : null
      )

      this.setState({
        filteredData: temp,
      })
    } else {
      this.setState({ filteredData: null })
    }
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "center",
          minHeight: "calc(100vh - 169px)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <TitleText subTitle left>
            General prices
          </TitleText>
          <Search
            placeholder="Search by location..."
            style={{ width: 300 }}
            onChange={e => this.filterByGeneral(e.target.value)}
          />
        </div>

        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
        >
          Add new device (no need for a "$" sign)
        </ParagraphText>
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
          colour={
            this.state.message === "Successfully added" ||
            this.state.message === "Successfully removed"
              ? Constants.mainGreen
              : Constants.mainRed
          }
        >
          {this.state.message}
        </ParagraphText>
        <div
          style={{
            width: "100%",
            maxWidth: 800,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <Input
            placeholder="Enter device..."
            style={{ marginRight: 25, minWidth: 400 }}
            onChange={e => this.onChangeDevice(e.target.value)}
          />

          <Input
            placeholder="Enter price..."
            style={{ marginRight: 25 }}
            onChange={e => this.onChangePrice(e.target.value)}
          />

          <Button type="primary" onClick={this.addValueToDB}>
            ADD
          </Button>
        </div>

        {this.state.isLoading ? (
          <Spin size="large" style={{ marginTop: 60 }} />
        ) : (
          <List
            itemLayout="horizontal"
            pagination={{
              pageSize: 20,
              hideOnSinglePage: true,
            }}
            dataSource={
              this.state.filteredData
                ? Object.keys(this.state.filteredData)
                : Object.keys(this.state.data)
            }
            style={{ marginTop: 35, maxWidth: 635, textAlign: "left" }}
            renderItem={item => (
              <List.Item
                actions={[
                  <a onClick={() => this.removeValueFromDB(item)}>delete</a>,
                ]}
              >
                <List.Item.Meta title={item} />
                <div>{"$" + this.state.data[item]}</div>
              </List.Item>
            )}
          />
        )}
      </div>
    )
  }
}
