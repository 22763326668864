// Import dependencies
import React from "react";
import { Layout, Menu } from "antd";
import { navigate, Link } from "gatsby";

// Import components
import UsersLayout from "./usersLayout";
import GeneralOrderLayout from "./generalOrderLayout";
import DropOffLocations from "./dropOffLocations";
import GeneralPrices from "./generalPrices";
import PremiumPrices from "./premiumPrices";
import PromoCodes from "./promoCodes";
import UploadPremiumPrices from "./uploadPremiumPrices";
import HeaderSeparator from "../separators/headerSeparator";
import Logo from "../../assets/icons/logo_green_login.svg";
import AdminOrders from "./orders";
import AdminPayout from "./payout";

// Import styles

const { Content, Footer, Sider } = Layout;

/*
    Admin Cabinet layout
*/
export default class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "orders"
    };
  }

  changeView = view => {
    this.setState({ currentView: view });
  };

  setOrderPaymentCount = (order, payment) => {
    this.props.setOrderPaymentCount(order, payment)
  };

  render() {
    return (
      <div className="admin_container">
        <div className="admin_side_menu">
            <Link to="/" className="checkout_logo" >
              <Logo />
            </Link>
            <h1>ADMIN MENU</h1>
            <div className={this.state.currentView === "orders" ? "admin_menu_item ative" : "admin_menu_item"} onClick={() => this.changeView("orders")} >
              Order & Credits
              <span>{this.props.orderCount}</span>
            </div>
            <div className={this.state.currentView === "payout" ? "admin_menu_item ative" : "admin_menu_item"} onClick={() => this.changeView("payout")} >
            Payouts
              <span>{this.props.paymentCount}</span>
            </div>
            <div className={this.props.currentView === "upload-prices" ? "admin_menu_item ative" : "admin_menu_item"} onClick={() => this.changeView("upload-prices")}>
              Upload prices
            </div>
            <div className="admin_menu_item admin_menu_item_red" onClick={() => navigate("/")}>
              Log out
            </div>
        </div>
        <div className="admin_content">
            {this.state.currentView === "orders" ? (
              <AdminOrders setOrderPaymentCount={this.setOrderPaymentCount}/>
            ) : this.state.currentView === "payout" ? (
              <AdminPayout setOrderPaymentCount={this.setOrderPaymentCount}/>
            ) : this.state.currentView === "upload-prices" ? (
              <UploadPremiumPrices />
            ) : null}
        </div>
      </div>
    );
  }
}
