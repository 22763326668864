const doFilter = (item, keyWord) => {
  if (!(keyWord instanceof RegExp)) {
    keyWord = new RegExp(keyWord, "i")
  }

  var nameString = item.info.account_info.email

  return keyWord.test(nameString)
}

const createFilter = keyWord => {
  return item => doFilter(item, keyWord)
}

export { createFilter }
