// Import dependencies
import React from "react"
import {
  List,
  Input,
  Select,
  Button,
  Card,
  Divider,
  Icon,
  Drawer,
  Collapse,
  Spin,
} from "antd"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"

// Import firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

const { Option } = Select
const { Panel } = Collapse

/*
    PremiumPrices layout
*/
export default class PremiumPrices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      isLoading: true,
      categoriesLoading: true,

      selectedCategory: null,
      selectedBrand: null,

      drawerVisible: false,
      drawerTitle: "",
      newCategorySelect: null,
      newBrandSelect: null,
      // Data to sent to firestore
      newCategory: null,
      newBrand: null,
      newDeviceName: null,
      powersOnExcellent: null,
      powersOnGood: null,
      powersOnPoorBroken: null,
      doesNotPowerOnExcellent: null,
      doesNotPowerGood: null,
      doesNotPowerPoorBroken: null,
      // Adding new item user messages
      addNewMessage: "",
      updating: false,

      // bulk update state
      bulkUpdateCategory: null,
      bulkUpdateBrand: null,
      percentChange: null,
      bulkUpdateMessage: "",

      message: "",
    }
    this.calcPercentChange = this.calcPercentChange.bind(this)
  }

  componentDidMount() {
    this.loadDataFromFirestore()
  }

  loadDataFromFirestore = () => {
    this.setState({ isLoading: true, categoriesLoading: true })

    // Fetch user data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("premium_price_table_new")
        .get()
        .then(querySnapshot => {
          this.setState({ isLoading: false })
          querySnapshot.forEach(doc => {
            let temp = this.state.data
            temp[doc.id] = doc.data()
            this.setState({ data: temp })
          })

          this.setState({ categoriesLoading: false })
        })
    })
  }

  // Update display category/brand
  updateCategory = category => {
    this.setState({ selectedCategory: category, selectedBrand: null })
  }
  updateBrand = brand => {
    this.setState({ selectedBrand: brand })
  }
  addNewCategory = () => {
    this.hideDrawer()

    let temp = this.state.data
    if (!temp[this.state.newCategory.toUpperCase()]) {
      temp[this.state.newCategory.toUpperCase()] = {}
      this.setState({
        data: temp,
        addNewMessage: "Category added, select from dropdown",
      })
    }
  }
  addNewBrand = () => {
    this.hideDrawer()

    let temp = this.state.data

    if (
      !temp[this.state.newCategorySelect][this.state.newBrand.toUpperCase()]
    ) {
      temp[this.state.newCategorySelect][this.state.newBrand.toUpperCase()] = {}
      this.setState({
        data: temp,
        addNewMessage: "Brand added, select from dropdown",
      })
    }
  }

  // On chnage handlers for new categories and brands
  // from the modal
  updateNewCategory = value => {
    this.setState({ newCategory: value })
  }
  updateNewBrand = value => {
    this.setState({ newBrand: value })
  }

  // Shows/hides drawer
  showDrawer = title => {
    this.setState({
      drawerVisible: true,
      newCategory: null,
      newBrand: null,
      drawerTitle: title,
    })
  }
  hideDrawer = () => {
    this.setState({
      drawerVisible: false,
      newCategory: null,
      newBrand: null,
      drawerTitle: "",
    })
  }

  addNewDevice = () => {
    // Check that all fields are there
    if (
      this.state.newCategorySelect &&
      this.state.newBrandSelect &&
      this.state.newDeviceName &&
      this.state.powersOnExcellent &&
      this.state.powersOnGood &&
      this.state.powersOnPoorBroken &&
      this.state.doesNotPowerOnExcellent &&
      this.state.doesNotPowerGood &&
      this.state.doesNotPowerPoorBroken
    ) {
      // Build new item object
      const newItem = {
        doesNotPowerOn: {
          excellent: this.state.doesNotPowerOnExcellent,
          good: this.state.doesNotPowerGood,
          "poor/broken": this.state.doesNotPowerPoorBroken,
        },
        powersOn: {
          excellent: this.state.powersOnExcellent,
          good: this.state.powersOnGood,
          "poor/broken": this.state.powersOnPoorBroken,
        },
      }

      // update current state
      let temp = this.state.data
      temp[this.state.newCategorySelect][this.state.newBrandSelect][
        this.state.newDeviceName.toUpperCase()
      ] = newItem

      // Push to firestore
      this.pushToFirestore(temp)
    } else {
      this.setState({ addNewMessage: "Please check all fields" })
    }
  }

  pushToFirestore = data => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    this.setState({ updating: true })
    let docsWritten = 1
    let totalToWrite = Object.keys(data).length

    Object.keys(data).map(item => {
      Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore()
          .collection("premium_price_table_new")
          .doc(item)
          .set(data[item])
          .then(() => {
            if (docsWritten === totalToWrite) {
              this.setState({ updating: false, addNewMessage: "" })
            } else docsWritten++
          })
          .catch()
      })
    })
  }
  removeFromFirebase = item => {
    // remove from state
    let temp = this.state.data
    delete temp[this.state.selectedCategory][this.state.selectedBrand][item]
    this.setState({ data: temp })

    // remove from firestore
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    let path = `${this.state.selectedBrand}.${item}`

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("premium_price_table_new")
        .doc(this.state.selectedCategory)
        .update({
          [path]: firebase.firestore.FieldValue.delete(),
        })
    })
  }

  getPercentChange = (value, percent) => {
    return (parseInt(value.split(",").join("")) * (1 + percent))
      .toFixed()
      .toString()
  }

  calcPercentChange = async () => {
    let percent = parseFloat(this.state.percentChange) / 100
    let updatedValues = this.state.data[this.state.bulkUpdateCategory][
      this.state.bulkUpdateBrand
    ]

    Object.keys(updatedValues).forEach(item => {
      // update doesNotPowerOn
      updatedValues[item].doesNotPowerOn.excellent = this.getPercentChange(
        updatedValues[item].doesNotPowerOn.excellent,
        percent
      )
      updatedValues[item].doesNotPowerOn.good = this.getPercentChange(
        updatedValues[item].doesNotPowerOn.good,
        percent
      )
      updatedValues[item].doesNotPowerOn["poor/broken"] = this.getPercentChange(
        updatedValues[item].doesNotPowerOn["poor/broken"],
        percent
      )

      // update powersOn
      updatedValues[item].powersOn.excellent = this.getPercentChange(
        updatedValues[item].powersOn.excellent,
        percent
      )
      updatedValues[item].powersOn.good = this.getPercentChange(
        updatedValues[item].powersOn.good,
        percent
      )
      updatedValues[item].powersOn["poor/broken"] = this.getPercentChange(
        updatedValues[item].powersOn["poor/broken"],
        percent
      )
    })
    return updatedValues
  }

  bulkPriceUpdate = () => {
    if (parseFloat(this.state.percentChange)) {
      this.calcPercentChange().then(value => {
        let temp = this.state.data
        temp[this.state.bulkUpdateCategory][this.state.bulkUpdateBrand] = value

        // Update state
        this.setState({ data: temp })

        // Update firebase
        this.pushToFirestore(temp)
      })
    } else {
      this.setState({ bulkUpdateMessage: "Should be a number" })
    }
  }

  updateSingleValue = (value, brand, state, condition) => {
    let temp = this.state.data
    temp[this.state.selectedCategory][this.state.selectedBrand][brand][state][
      condition
    ] = value

    this.setState({ data: temp })
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "center",
          minHeight: "calc(100vh - 169px)",
        }}
      >
        {/* Page title */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <TitleText subTitle left>
            Premium prices
          </TitleText>
        </div>

        {/* Add new device */}
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
        >
          Add new device
        </ParagraphText>
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
          colour={
            this.state.addNewMessage ===
              "Category added, select from dropdown" ||
            this.state.addNewMessage === "Brand added, select from dropdown"
              ? Constants.mainGreen
              : Constants.mainRed
          }
        >
          {this.state.addNewMessage}
        </ParagraphText>
        <Collapse style={{ maxWidth: 860, marginBottom: 40 }}>
          <Panel
            header="Click here to add new"
            key="1"
            style={{ textAlign: "left" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: 800,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 40,
                }}
              >
                <Select
                  style={{
                    width: 250,
                    maxWidth: 250,
                    minWidth: 250,
                    marginRight: 25,
                  }}
                  showSearch
                  placeholder="Select category"
                  loading={this.state.categoriesLoading}
                  onChange={value =>
                    this.setState({ newCategorySelect: value })
                  }
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{ padding: "4px 8px", cursor: "pointer" }}
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => this.showDrawer("category")}
                      >
                        <Icon type="plus" /> Add category
                      </div>
                    </div>
                  )}
                >
                  {Object.keys(this.state.data).map((item, i) => (
                    <Option value={item} key={i}>
                      {item}
                    </Option>
                  ))}
                </Select>

                {this.state.newCategorySelect && (
                  <Select
                    style={{
                      width: 250,
                      maxWidth: 250,
                      minWidth: 250,
                      marginRight: 25,
                    }}
                    showSearch
                    placeholder="Select brand"
                    onChange={value => this.setState({ newBrandSelect: value })}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{ padding: "4px 8px", cursor: "pointer" }}
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => this.showDrawer("brand")}
                        >
                          <Icon type="plus" /> Add brand
                        </div>
                      </div>
                    )}
                  >
                    {Object.keys(
                      this.state.data[this.state.newCategorySelect]
                    ).map((item, i) => (
                      <Option value={item} key={i}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                )}

                {this.state.newCategorySelect && (
                  <Input
                    placeholder="Add device name..."
                    style={{
                      width: 250,
                      maxWidth: 250,
                      minWidth: 250,
                    }}
                    onChange={e =>
                      this.setState({ newDeviceName: e.target.value })
                    }
                  />
                )}
              </div>

              <div
                style={{
                  width: "100%",
                  maxWidth: 825,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 40,
                }}
              >
                {/* Does not power on */}
                <div
                  style={{
                    flex: 1,
                    marginRight: 25,
                    border: "1px solid rgba(220, 230, 237, 1.00)",
                    padding: 25,
                  }}
                >
                  <ParagraphText separator>Does not power on</ParagraphText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <ParagraphText>Excellent</ParagraphText>
                      <Input
                        placeholder="Price..."
                        allowClear
                        style={{ maxWidth: 140 }}
                        prefix="$"
                        onChange={e =>
                          this.setState({
                            doesNotPowerOnExcellent: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <ParagraphText>Good</ParagraphText>
                      <Input
                        placeholder="Price..."
                        allowClear
                        style={{ maxWidth: 140 }}
                        prefix="$"
                        onChange={e =>
                          this.setState({
                            doesNotPowerGood: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <ParagraphText>Poor/broken</ParagraphText>
                      <Input
                        placeholder="Price..."
                        allowClear
                        style={{ maxWidth: 140 }}
                        prefix="$"
                        onChange={e =>
                          this.setState({
                            doesNotPowerPoorBroken: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Power on */}
                <div
                  style={{
                    flex: 1,
                    marginRight: 25,
                    border: "1px solid rgba(220, 230, 237, 1.00)",
                    padding: 25,
                  }}
                >
                  <ParagraphText separator>Power on</ParagraphText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <ParagraphText>Excellent</ParagraphText>
                      <Input
                        placeholder="Price..."
                        allowClear
                        style={{ maxWidth: 140 }}
                        prefix="$"
                        onChange={e =>
                          this.setState({
                            powersOnExcellent: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <ParagraphText>Good</ParagraphText>
                      <Input
                        placeholder="Price..."
                        allowClear
                        style={{ maxWidth: 140 }}
                        prefix="$"
                        onChange={e =>
                          this.setState({
                            powersOnGood: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <ParagraphText>Poor/broken</ParagraphText>
                      <Input
                        placeholder="Price..."
                        allowClear
                        style={{ maxWidth: 140 }}
                        prefix="$"
                        onChange={e =>
                          this.setState({
                            powersOnPoorBroken: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ width: 250, marginBottom: 20 }}
                onClick={this.addNewDevice}
              >
                <Button type="primary" block>
                  ADD DEVICE
                </Button>
              </div>
            </div>
          </Panel>
        </Collapse>

        <Divider />

        {/* Bulk update */}
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
        >
          Price bulk update (changes price by percent value)
        </ParagraphText>
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
          colour={
            this.state.bulkUpdateMessage === "Success"
              ? Constants.mainGreen
              : Constants.mainRed
          }
        >
          {this.state.bulkUpdateMessage}
        </ParagraphText>
        <Collapse style={{ maxWidth: 860, marginBottom: 40 }}>
          <Panel
            header="Click here to update the price in bulk"
            key="1"
            style={{ textAlign: "left" }}
          >
            <div style={{ display: "flex" }}>
              <Select
                style={{
                  width: 250,
                  minWidth: 250,
                  maxWidth: 250,
                  marginBottom: 25,
                  marginRight: 25,
                }}
                showSearch
                placeholder="Select category..."
                onChange={value => this.setState({ bulkUpdateCategory: value })}
                loading={this.state.categoriesLoading}
              >
                {Object.keys(this.state.data).map((item, i) => (
                  <Option value={item} key={i}>
                    {item}
                  </Option>
                ))}
              </Select>
              {this.state.bulkUpdateCategory && (
                <>
                  <Select
                    style={{
                      width: 250,
                      minWidth: 250,
                      maxWidth: 250,
                      marginBottom: 25,
                      marginRight: 25,
                    }}
                    showSearch
                    placeholder="Select or type..."
                    onChange={value =>
                      this.setState({ bulkUpdateBrand: value })
                    }
                    loading={this.state.categoriesLoading}
                  >
                    {Object.keys(
                      this.state.data[this.state.bulkUpdateCategory]
                    ).map((item, i) => (
                      <Option value={item} key={i}>
                        {item}
                      </Option>
                    ))}
                  </Select>

                  {this.state.bulkUpdateBrand && (
                    <Input
                      placeholder="E.g. -5 or +10.54"
                      allowClear
                      style={{
                        width: 250,
                        minWidth: 250,
                        maxWidth: 250,
                        marginBottom: 25,
                      }}
                      onChange={e =>
                        this.setState({ percentChange: e.target.value })
                      }
                    />
                  )}
                </>
              )}
            </div>

            {this.state.bulkUpdateCategory &&
              this.state.bulkUpdateCategory &&
              this.state.percentChange && (
                <div
                  style={{ width: 250, marginBottom: 20 }}
                  onClick={this.bulkPriceUpdate}
                >
                  <Button type="primary" block>
                    UPDATE PRICE
                  </Button>
                </div>
              )}
          </Panel>
        </Collapse>

        <Divider />

        {/* Display settings */}
        <div style={{ width: "100%", display: "flex", marginTop: 60 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 25,
              width: 300,
            }}
          >
            <ParagraphText
              margin="margin-bottom: 15px"
              style={{ textAlign: "left" }}
            >
              Select category to display
            </ParagraphText>
            <Select
              style={{ width: 300 }}
              showSearch
              placeholder="Select or type..."
              onChange={this.updateCategory}
              loading={this.state.categoriesLoading}
            >
              {Object.keys(this.state.data).map((item, i) => (
                <Option value={item} key={i}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>

          {this.state.selectedCategory && (
            <div
              style={{ display: "flex", flexDirection: "column", width: 300 }}
            >
              <ParagraphText
                margin="margin-bottom: 15px"
                style={{ textAlign: "left" }}
              >
                Select brand to display
              </ParagraphText>
              <Select
                style={{ width: 300 }}
                showSearch
                placeholder="Select or type..."
                onChange={this.updateBrand}
                loading={this.state.categoriesLoading}
              >
                {Object.keys(this.state.data[this.state.selectedCategory]).map(
                  (item, i) => (
                    <Option value={item} key={i}>
                      {item}
                    </Option>
                  )
                )}
              </Select>
            </div>
          )}
        </div>

        {this.state.selectedCategory && this.state.selectedBrand ? (
          <List
            grid={{ gutter: 0, column: 1 }}
            style={{ marginTop: 60, maxWidth: 770, minWidth: 770 }}
            dataSource={Object.keys(
              this.state.data[this.state.selectedCategory][
                this.state.selectedBrand
              ]
            )}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            renderItem={item => (
              <List.Item style={{ textAlign: "left", marginBottom: 40 }}>
                <Card
                  title={item}
                  extra={
                    <p
                      style={{ color: Constants.mainRed, cursor: "pointer" }}
                      onClick={() => this.removeFromFirebase(item)}
                    >
                      remove
                    </p>
                  }
                >
                  <div
                    style={{ width: "100%", display: "flex", marginBottom: 25 }}
                  >
                    {Object.keys(
                      this.state.data[this.state.selectedCategory][
                        this.state.selectedBrand
                      ][item]
                    ).map((val, ind) => (
                      <div
                        style={{
                          flex: 1,
                          marginRight: 25,
                          border: "1px solid rgba(220, 230, 237, 1.00)",
                          padding: 25,
                        }}
                        key={ind}
                      >
                        <ParagraphText separator>
                          {val === "powersOn"
                            ? "Powers on"
                            : "Does not power on"}
                        </ParagraphText>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {Object.keys(
                            this.state.data[this.state.selectedCategory][
                              this.state.selectedBrand
                            ][item][val]
                          ).map((condition, indx) => (
                            <div
                              key={indx}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 20,
                                justifyContent: "space-between",
                              }}
                            >
                              <ParagraphText>{condition}</ParagraphText>
                              <Input
                                placeholder="Price..."
                                allowClear
                                style={{ maxWidth: 140 }}
                                prefix="$"
                                defaultValue={
                                  this.state.data[this.state.selectedCategory][
                                    this.state.selectedBrand
                                  ][item][val][condition]
                                }
                                onChange={e =>
                                  this.updateSingleValue(
                                    e.target.value,
                                    item,
                                    val,
                                    condition
                                  )
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    type="primary"
                    onClick={() => this.pushToFirestore(this.state.data)}
                  >
                    Update
                  </Button>
                </Card>
              </List.Item>
            )}
          />
        ) : null}

        {/* Drawer component for input */}
        {this.state.drawerVisible && (
          <Drawer
            title={"Add new " + this.state.drawerTitle}
            placement="right"
            closable
            maskClosable
            width={400}
            onClose={this.hideDrawer}
            visible={this.state.drawerVisible}
            style={{ marginTop: 80, height: "calc(100vh - 80px" }}
          >
            <Input
              placeholder={"New " + this.state.drawerTitle + "..."}
              style={{ marginBottom: 20, marginTop: 20 }}
              allowClear
              onChange={e =>
                this.state.drawerTitle === "category"
                  ? this.updateNewCategory(e.target.value)
                  : this.updateNewBrand(e.target.value)
              }
            />
            <Button
              type="primary"
              block
              onClick={() =>
                this.state.drawerTitle === "category"
                  ? this.addNewCategory()
                  : this.addNewBrand()
              }
            >
              ADD
            </Button>
          </Drawer>
        )}

        {/* loader to show when data is writte to firebase */}
        {this.state.updating && (
          <div
            style={{
              width: "calc(100% - 201px)",
              height: "calc(100vh - 81px)",
              position: "fixed",
              backgroundColor: "rgba(255,255,255,.9)",
              top: 81,
              left: 201,
              zIndex: 9999999999,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    )
  }
}
