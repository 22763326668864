// Import dependencies
import React from "react"
import { Spin, Skeleton, List, Input, Select, Button } from "antd"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"

// Import firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

// Import utils
import { createFilter } from "../../util/filterByLocation"

const { Search } = Input
const { Option } = Select

/*
    DropOffLocations layout
*/
export default class DropOffLocations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      isLoading: true,
      currentTab: "CENTRAL",
      filteredData: null,
      area: null,
      location: null,
      message: "",
    }
    this.updateLocation = this.updateLocation.bind(this)
    this.onChangeSelect = this.onChangeSelect.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
  }

  componentDidMount() {
    // Fetch user data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("drop_off_locations")
        .get()
        .then(querySnapshot => {
          let result = {}

          querySnapshot.forEach(doc => {
            result[doc.id] = doc.data()
          })

          this.setState({
            data: result,
            isLoading: false,
          })
        })
    })
  }

  updateLocation = location => {
    this.setState({ currentTab: location })
  }

  filterData(keyWord) {
    if (keyWord !== "") {
      let data = this.state.data[this.state.currentTab].locations
      return data.filter(createFilter(keyWord))
    }
  }
  filterByLocation = keyWord => {
    if (keyWord === "") {
      this.setState({ filteredData: null })
      return
    }

    var result = this.filterData(keyWord)
    if (result) {
      this.setState({ filteredData: result })
    } else {
      this.setState({ filteredData: null })
    }
  }

  onChangeSelect = value => {
    this.setState({ area: value })
  }

  onChangeInput = value => {
    this.setState({ location: value })
  }

  addValueToDB = () => {
    if (this.state.area && this.state.location) {
      const app = import("firebase/app")
      const firestore = import("firebase/firestore")

      Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore()
          .collection("drop_off_locations")
          .doc(this.state.area)
          .update({
            locations: firebase.firestore.FieldValue.arrayUnion({
              value: this.state.location,
            }),
          })
          .then(() => {
            this.setState({ message: "Success" })
          })
      })
    } else {
      this.setState({ message: "Please select area and add location." })
    }
  }

  removeValueFromDB = value => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("drop_off_locations")
        .doc(this.state.currentTab)
        .set({
          locations: this.state.data[this.state.currentTab].locations.filter(
            item => item.value !== value
          ),
        })
        .then(this.setState({ message: "Successful deletion" }))
        .catch()
    })
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "center",
          minHeight: "calc(100vh - 169px)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <TitleText subTitle left>
            Drop-off locations
          </TitleText>
          <Search
            placeholder="Search by location..."
            style={{ width: 300 }}
            onChange={e => this.filterByLocation(e.target.value)}
          />
        </div>

        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
        >
          Add new location
        </ParagraphText>
        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
          colour={
            this.state.message === "Success" ||
            this.state.message === "Successful deletion"
              ? Constants.mainGreen
              : Constants.mainRed
          }
        >
          {this.state.message}
        </ParagraphText>
        <div
          style={{
            width: "100%",
            maxWidth: 800,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          <Select
            style={{ maxWidth: 250, marginRight: 25 }}
            placeholder="Select area..."
            onChange={this.onChangeSelect}
          >
            {Object.keys(this.state.data).map((item, i) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>

          <Input
            placeholder="Enter location..."
            style={{ marginRight: 25 }}
            onChange={e => this.onChangeInput(e.target.value)}
          />

          <Button type="primary" onClick={this.addValueToDB}>
            ADD
          </Button>
        </div>

        <ParagraphText
          margin="margin-bottom: 15px"
          style={{ textAlign: "left" }}
        >
          Click the links to navigate
        </ParagraphText>
        <div style={{ width: "100%", display: "flex" }}>
          {!this.state.isLoading
            ? Object.keys(this.state.data).map((key, i) => (
              <ParagraphText
                separator
                link
                margin="margin-right: 25px"
                colour={Constants.titleColour}
                key={i}
                onClick={() => this.updateLocation(key)}
              >
                {key}
              </ParagraphText>
            ))
            : null}
        </div>
        {this.state.isLoading ? (
          <Spin size="large" style={{ marginTop: 60 }} />
        ) : (
          <List
            itemLayout="horizontal"
            style={{ marginTop: 35, maxWidth: 450 }}
            dataSource={
              this.state.filteredData
                ? this.state.filteredData
                : (this.state.data.hasOwnProperty(this.state.currentTab) ? this.state.data[this.state.currentTab].locations : '')
            }
            pagination={{
              pageSize: 20,
              hideOnSinglePage: true,
            }}
            renderItem={item => (
              <List.Item
                actions={[
                  <a onClick={() => this.removeValueFromDB(item.value)}>
                    delete
                  </a>,
                ]}
              >
                <Skeleton title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={item.value}
                    style={{ textAlign: "left" }}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        )}
      </div>
    )
  }
}
